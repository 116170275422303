import { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../contexts/user";
import { getSelectedImage, uploadImage } from "../utils/image_upload";
import { Loading } from "../components/Loading";


// import { Form, Button, InputGroup, FormControl } from 'react-bootstrap';
export function EditProfilePage() {
  const { getValidAccessToken } = useContext(UserContext);

  const navigate = useNavigate();
  const location = useLocation();

  const [profile, setProfile] = useState({});
  const [profileImageHasChanged, setProfileImageHasChanged] = useState(false);
  let [logoImageHasChanged, setLogoImageHasChanged] = useState(false);

  const [form, setForm] = useState({
    Phone: "",
    CalendlyUrl__c: "",
  });

  const [formErrors, setFormErrors] = useState({
    Phone: "",
    CalendlyUrl__c: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const [profileImage, setProfileImage] = useState(null);
  const [logoImage, setLogoImage] = useState(null);

  useEffect(() => {
    getProfile();
  }, []);

  // Get Salesforce Access Token
  const getProfile = async () => {
    setIsLoading(true);

    const access_token = await getValidAccessToken();

    const endpoint = `https://eu-central-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_REALM_APP_ID}/endpoint/salesforce/contact`;

    const response = await fetch(endpoint, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + access_token,
      },
    });

    const data = await response.json();

    const contactInfo = {
      Anrede: data.Salutation,
      Vorname: data.FirstName,
      Nachname: data.LastName,
      "E-Mail": data.Email,
      "Kalender Buchungs Link": data.CalendlyUrl__c,
      Profilbild: data.ProfileImageUrl__c,
    };

    // Remove empty fields
    Object.keys(contactInfo).forEach(
      (key) =>
        (contactInfo[key] == null || contactInfo[key] === "") &&
        delete contactInfo[key]
    );

    setProfile(contactInfo);
    setForm({
      Phone: data.Phone,
      CalendlyUrl__c: data.CalendlyUrl__c,
    });
    setLogoImage(data.Account.LogoUrl__c);
    setProfileImage(data.ProfileImageUrl__c);
    setIsLoading(false);
  };

  // As explained in the Login page.
  const onFormInputChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });

    // Use a switch statement for field-specific validation
    switch (name) {
      case "Email":
        if (!value.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
          setFormErrors({
            ...formErrors,
            Email: "Ungültige E-Mail-Adresse",
          });
        } else {
          setFormErrors({
            ...formErrors,
            Email: "",
          });
        }
        break;

      case "CalendlyUrl__c":
        if (value.length === 0) {
          setFormErrors({
            ...formErrors,
            CalendlyUrl__c: "",
          });
        }
        // Match calendly.com links
        else if (!value.match(/^(https:\/\/calendly.com\/)([a-zA-Z0-9-]+)$/)) {
          setFormErrors({
            ...formErrors,
            CalendlyUrl__c: "Ungültiger Calendly Link",
          });
        } else {
          setFormErrors({
            ...formErrors,
            CalendlyUrl__c: "",
          });
        }
        break;

      // Watchout for Phone validation
      case "Phone": // Match phone numbers
        if (value.length === 0) {
          setFormErrors({
            ...formErrors,
            Phone: "",
          });
        } else {
          setFormErrors({
            ...formErrors,
            Phone: "",
          });
        }
        break;

      default:
        if (value.length < 1) {
          setFormErrors({
            ...formErrors,
            [name]: "Dieses Feld darf nicht leer sein",
          });
        } else {
          setFormErrors({
            ...formErrors,
            [name]: "",
          });
        }
        break;
    }
  };

  // As explained in the Login page.
  const redirectNow = () => {
    const redirectTo = location.search.replace("?redirectTo=", "");
    navigate(redirectTo ? redirectTo : "/profil");
  };

  // As explained in the Login page.
  const onSubmit = async () => {
    // Add your form submission logic here
    // if (
    //   Boolean(
    //     formErrors.Email ||
    //       formErrors.Salutation ||
    //       formErrors.FirstName ||
    //       formErrors.LastName ||
    //       formErrors.Phone ||
    //       formErrors.CalendlyUrl__c ||
    //       false
    //   )
    // ) {
    //   return;
    // }

    try {
      await updateProfile();

      redirectNow();
    } catch (err) {
      console.error(err);
    }
  };

  // Send request to update profile
  const updateProfile = async () => {
    setIsLoading(true);

    const access_token = await getValidAccessToken();

      if (profileImage && profileImageHasChanged) {
        const imageUrl = await handleImageUpload(profileImage, 'profileImage');
        form.ProfileImageUrl__c = imageUrl;
      } else {
        delete form.ProfileImageUrl__c;
      }

      if (logoImage && logoImageHasChanged) {
        const imageUrl = await handleImageUpload(logoImage, 'logo');
        form.LogoImageUrl__c = imageUrl;
      } else {
        delete form.LogoImageUrl__c;
      }

    const endpoint = `https://eu-central-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_REALM_APP_ID}/endpoint/users`;

    const response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + access_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    });

    const data = await response.json();

    setIsLoading(false);
  };

  async function handleProfileImageChange(e) {
    setProfileImage(await getSelectedImage(e));
    setProfileImageHasChanged(true);
    return profileImage;
  }

  async function handleLogoImageChange(e) {
    setLogoImage(await getSelectedImage(e));
    setLogoImageHasChanged(true);
    return logoImage;
  }

  async function handleImageUpload(image, fileName) {
    const imgUrl = await uploadImage(image, fileName);

    return imgUrl;
  }

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 font-sans font-medium font-lg">
      <h1 class="text-center">
        Profil bearbeiten
      </h1>

      {/* If loading, display loading */}
      {isLoading || !profile ? (
        <Loading/>
      ) : (
        <div>
        <div className="sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <form className="space-y-6">
    
            <span class="block text-sm font-medium leading-6 text-gray-900">Logo hochladen</span>
            <div style={{ margin: "1rem" }}>
              {logoImage && (
                <div className="text-center">
                  <img
                    alt="not found"
                    width={"375px"}
                    src={logoImageHasChanged === true ? URL.createObjectURL(logoImage) : logoImage}
                    className="mr-4"
                  />
                  <div>
                    <input
                      id="logoImage"
                      type="file"
                      name="logoImage"
                      onChange={handleLogoImageChange}
                      hidden
                    />
                    <br/>
                    <div class="flex space-x-4 items-center">
                    <div class="w-1/2 h-[36px]">
                    <label for="logoImage">
                      <span className="flex cursor-pointer w-full justify-center rounded-md bg-link-color px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cta-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        Logo auswählen
                      </span>
                    </label>
                    </div>
                    <div class="w-1/2">
                    <button type="button" 
                            onClick={() => handleLogoImageChange(null)}
                            className="flex cursor-pointer w-full justify-center rounded-md bg-link-color px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cta-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                      Logo entfernen
                    </button>
                    </div>
                    </div>
                  </div>
                </div>
              )}
    
              {!logoImage && (
                <div className="flex items-center text-center">
                  <input
                    id="logoImage"
                    type="file"
                    name="logoImage"
                    onChange={handleLogoImageChange}
                    hidden
                  />
                  <label for="logoImage" className="m-2">
                    <span className="flex cursor-pointer w-full justify-center rounded-md bg-link-color px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cta-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                      Logo auswählen
                    </span>
                  </label>
                </div>
              )}
            </div>
    
            <span class="block text-sm font-medium leading-6 text-gray-900">Profilbild hochladen</span>
            <div style={{ margin: "1rem" }}>
              {profileImage && (
                <div className="text-center">
                  <img
                    alt="not found"
                    width={"375px"}
                    src={profileImageHasChanged === true ? URL.createObjectURL(profileImage) : profileImage}
                    className="mr-4"
                  />
                  <div>
                    <input
                      id="profileImage"
                      type="file"
                      name="profileImage"
                      onChange={handleProfileImageChange}
                      hidden
                    />
                    <br/>
                    <div class="flex space-x-4 items-center">
                    <div class="w-1/2 h-[36px]">
                    <label for="profileImage">
                      <span className="flex cursor-pointer w-full justify-center rounded-md bg-link-color px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cta-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        Profilbild auswählen
                      </span>
                    </label>
                    </div>
                    <div class="w-1/2">
                    <button type="button" 
                            onClick={() => handleProfileImageChange(null)}
                            className="flex cursor-pointer w-full justify-center rounded-md bg-link-color px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cta-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                      Profilbild entfernen
                    </button>
                    </div>
                    </div>
                  </div>
                </div>
              )}
    
              {!profileImage && (
                <div className="flex items-center text-center">
                  <input
                    id="profileImage"
                    type="file"
                    name="profileImage"
                    onChange={handleProfileImageChange}
                    hidden
                  />
                  <label for="profileImage" className="m-2">
                    <span className="flex cursor-pointer w-full justify-center rounded-md bg-link-color px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cta-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                      Profilbild auswählen
                    </span>
                  </label>
                </div>
              )}
            </div>
              <div>
                <label htmlFor="CalendlyUrl__c" className="block text-sm font-medium leading-6 text-gray-900">
                  Calendly Link
                </label>
                <div className="mt-2">
                  <input
                    name="CalendlyUrl__c"
                    type="text"
                    placeholder="https://calendly.com/dein-link"
                    onChange={onFormInputChange}
                    value={form.CalendlyUrl__c}
                    className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
    
              <div>
                <label htmlFor="Phone" className="block text-sm font-medium leading-6 text-gray-900">
                  Telefon<br/>
                  <div class="leading-[14px]">
                    <small>Die Telefonnummer unter der dich Deine Leads erreichen können. Diese zeigen wir beispielsweise in der personalisierten E-Mail an die Leads</small>
                  </div>
                </label>
                <div className="mt-2">
                  <input
                    name="Phone"
                    type="text"
                    value={form.Phone}
                    onChange={onFormInputChange}
                    className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
    
              <div>
                <button
                  onClick={onSubmit} type="button"
                  className="flex w-full justify-center rounded-md bg-link-color px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cta-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Speichern
                </button>
              </div>
            </form>
          </div>
        </div>
        </div>
      )}
    </div>
  );
}
