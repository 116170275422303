import { useContext } from "react";
import { UserContext } from "../contexts/user";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "../components/Loading";


export function Lead() {
  const { getValidAccessToken } = useContext(UserContext);
  const { id } = useParams();
  console.log('leadId', id);

  const [lead, setLead] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Get Salesforce Access Token
  const getSalesforceLead = async () => {
    setIsLoading(true);

    const access_token = await getValidAccessToken();

    const endpont = `https://eu-central-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_REALM_APP_ID}/endpoint/salesforce/lead?Id=${id}`;

    console.log("Endpoint: ", endpont);

    //const body = JSON.stringify(`{\"Id\": "${id}"}`);

    const response = await fetch(endpont, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + access_token,
      },
    });

    const data = await response.json();
    console.log("Data: ", data);

    if(data.records != null) {
        setLead(data.records[0]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getSalesforceLead();
  }, []);

  return (
    <>
      <div class="App">
        {isLoading && lead != null ? (
            <Loading/>
        ) : (
          <div class="flex justify-center text-left">
          <div class="relative overflow-x-auto lg:w-3/4 w-full lg:px-0 px-4">
            <h1 class="my-4 text-link-color text-left">Lead: {lead.Salutation} {lead.FirstName} {lead.LastName}</h1>
            <h3>Daten:</h3>
            <div class="flex">
                <div class="lg:w-2/12 w-full">
                    E-Mail:
                </div>
                <div class="lg:w-10/12 w-full">
                    <a href={`mailto:${lead.Email}`}>{lead.Email}</a>
                </div>
            </div>
            <div class="flex">
                <div class="lg:w-2/12 w-full">
                    Telefon:
                </div>
                <div class="lg:w-10/12 w-full">
                    <a href={`tel:${lead.Phone}`}>{lead.Phone}</a>
                </div>
            </div>
            <div class="flex">
                <div class="lg:w-2/12 w-full">
                    Postleitzahl:
                </div>
                <div class="lg:w-10/12 w-full">
                    {lead.PostalCode}
                </div>
            </div>
            <div>
            {lead.QuestionsAndAnswers__r != null ? (
                <div class="mt-4">
            <h3>Fragen und Antworten:</h3>
            {lead.QuestionsAndAnswers__r.records.map((questionAndAnswer, index) => (
                <div class="text-left" key={index}>
                  <h4>{questionAndAnswer.Question__c}</h4>
                  <p>{questionAndAnswer.Answer__c}</p>
                </div>
                ))}
                </div>
            ) : (<></>)}
            </div>
          </div>
          </div>
        )}
      </div>
    </>
  );
}
