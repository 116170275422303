import { Leads } from "./Leads";

export default function Home() {
  return (
    <>
      <div class="App">
        <Leads />
      </div>
    </>
  );
}
