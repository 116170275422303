import { useContext } from "react";
import { UserContext } from "../contexts/user";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Loading } from "../components/Loading";

export function Leads() {
  const { getValidAccessToken } = useContext(UserContext);

  const [leads, setLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  // Get Salesforce Access Token
  const getSalesforceLeads = async () => {
    setIsLoading(true);

    const access_token = await getValidAccessToken();

    const endpont = `https://eu-central-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_REALM_APP_ID}/endpoint/salesforce/leads`;

    const response = await fetch(endpont, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + access_token,
      },
    });

    const data = await response.json();

    setLeads(data.records);
    setIsLoading(false);
  };

  useEffect(() => {
    getSalesforceLeads();
  }, []);

  return (
    <>
      <div class="App">
        {isLoading && leads.length === 0 ? (
          <Loading/>
        ) : (
          <div class="flex justify-center">
          <div class="relative overflow-x-auto w-3/4">
            <h1 class="my-4 text-link-color text-left">Leads</h1>
            <table class="min-w-full leading-normal">
              <thead>
                <tr>
                  <th class="px-3 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Anrede</th>
                  <th class="px-3 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Name</th>
                  <th class="px-3 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">E-Mail</th>
                  <th class="px-3 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Telefon</th>
                  <th class="px-3 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">PLZ</th>
                  {/* <th class="px-3 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Complained</th> */}
                  {/* <th class="px-3 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Refunded</th> */}
                </tr>
              </thead>
              <tbody>
                {leads.map((lead, index) => (
                  <tr key={index}>
                    <td class="px-3 py-3 border-b border-gray-200 bg-white text-m text-left">{lead.Salutation}</td>
                    <td class="px-3 py-3 border-b border-gray-200 bg-white text-m text-left">
                      <Link to={`/leads/${lead.Id}`} className="block lg:inline-block lg:mt-0 no-underline text-link-color hover:text-cta-color mr-4">{lead.FirstName} {lead.LastName}</Link>
                      </td>
                    <td class="px-3 py-3 border-b border-gray-200 bg-white text-m text-left">
                      <a href={`mailto:${lead.Email}`}>{lead.Email}</a>
                    </td>
                    <td class="px-3 py-3 border-b border-gray-200 bg-white text-m text-left">
                      <a href={`tel:${lead.Phone}`}>{lead.Phone}</a>
                    </td>
                    <td class="px-3 py-3 border-b border-gray-200 bg-white text-m text-left">{lead.PostalCode}</td>
                    {/* <td class="px-3 py-3 border-b border-gray-200 bg-white text-m">{lead.Complained__c.toString()}</td> */}
                    {/* <td class="px-3 py-3 border-b border-gray-200 bg-white text-m">{lead.Refunded__c.toString()}</td> */}
                  </tr>
                ))}
                {leads.length === 0 && (
                  <td colSpan="5" class="px-3 py-3 border-b border-gray-200 bg-white text-m text-left">Noch keine Leads vorhanden.</td>
                )}
              </tbody>
            </table>
          </div>
          </div>
        )}
        {isLoading && leads.length > 0 ? <Loading/> : null}
      </div>
    </>
  );
}
