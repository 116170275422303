import "./App.css";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import UnderConstruction from "./pages/under_construction/under_construction";
import { UserProvider } from "./contexts/user";
import Home from "./pages/Home";
import Login from "./pages/Login";
import PrivateRoute from "./pages/PrivateRoute";
import Signup from "./pages/Signup";
import { Leads } from "./pages/Leads";
import { Lead } from "./pages/Lead";
import { AccountPage } from "./pages/Account";
import { ContactPage } from "./pages/Contact";
import { ProfilePage } from "./pages/Profile";
import { EditProfilePage } from "./pages/Edit_Profile";
import { CRMIntegrationPage } from "./pages/CRM_Integration";
import ResetPasswordPage from "./pages/ResetPassword";
import ConfirmResetPasswordPage from "./pages/ConfirmResetPassword";


function App() {
  return (
    <BrowserRouter>
      {/* We are wrapping our whole app with UserProvider so that */}
      {/* our user is accessible through out the app from any page*/}
      <UserProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/registrierung" element={<Signup />} />
          <Route path="/passwort-vergessen" element={<ResetPasswordPage />} />
          <Route path="/passwort-zuruecksetzen" element={<ConfirmResetPasswordPage />} />
          {/* We are protecting our Home Page from unauthenticated */}
          {/* users by wrapping it with PrivateRoute here. */}
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<Home />} />
            <Route path="/start" element={<Home />} />
            <Route path="/leads" element={<Leads />} />
            <Route path="/leads/:id" element={<Lead />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/account" element={<AccountPage />} />
            {/* <Route path="/profil" element={<ProfilePage />} /> */}
            <Route path="/crm-integration" element={<CRMIntegrationPage />} />
            <Route path="/profil" element={<EditProfilePage />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </UserProvider>
    </BrowserRouter>
  );

  return (
    <div className="App">
      <main>
        <UnderConstruction /> {/* Render the UnderConstruction component */}
      </main>
    </div>
  );
}

function PageNotFound() {
  return (
    <div>
      <p>404 Page not found</p>
    </div>
  );
}

export default App;
