import { storage } from "../firebaseConfig";
import { v4 as uuidv4 } from "uuid";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import * as Realm from "realm-web";

const REALM_APP_ID = process.env.REACT_APP_REALM_APP_ID;
const app = new Realm.App({ id: REALM_APP_ID });

export async function getSelectedImage(e) {
  if (!e) return null;
  const selectedFile = e.target.files[0];

  if (selectedFile) {
    const allowedTypes = ["image/jpeg", "image/png"];
    if (allowedTypes.includes(selectedFile.type)) {
      return selectedFile;
    } else {
      alert("Bitte wähle ein .jpg oder .png aus.");
      return null;
    }
  } else return null;
}

export async function uploadImage(image, fileName) {
  // Generate uuid for the image
  const uuid = uuidv4();

  const imageName = `${fileName}.${getFileType(image.name)}`;

  if (image) {
    const imageRef = ref(storage, `images/${uuid}/${imageName}`);

    const uploadTask = await uploadBytes(imageRef, image);

    const downloadUrl = await getDownloadURL(imageRef);

    return downloadUrl;
  } else {
    alert("Please select an image before uploading.");
    return null;
  }
}

function getFileType(fileName) {
  return fileName.substring(fileName.lastIndexOf('.')+1, fileName.length) || '.jpg';
}