import { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../contexts/user";
import { Loading } from "../components/Loading";

// import { Form, Button, InputGroup, FormControl } from 'react-bootstrap';

export function CRMIntegrationPage() {
  const { getValidAccessToken } = useContext(UserContext);

  const navigate = useNavigate();
  const location = useLocation();

  const [formErrors, setFormErrors] = useState({
    LeadWebHookUrl__c: "",
  });

  const [profile] = useState({});

  const [form, setForm] = useState({
    LeadWebHookUrl__c: "",
  });

  const [contactData, setContactData] = useState({
    contactId: "",
  }); 

  const [isLoading, setIsLoading] = useState(false);

  const [image, setImage] = useState(null);
  const [url, setUrl] = useState("");
  const [progress, setProgress] = useState(0);

  let accountId;

  useEffect(() => {
    getProfile();
  }, []);

  // Get Salesforce Access Token
  const getProfile = async () => {
    setIsLoading(true);

    const access_token = await getValidAccessToken();

    const endpoint = `https://eu-central-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_REALM_APP_ID}/endpoint/salesforce/contact`;

    const response = await fetch(endpoint, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + access_token,
      },
    });

    const data = await response.json();
    accountId = data.AccountId;

    setContactData({
        contactId: data.Id,
    });

    const contactInfo = {
        LeadWebhookUrl__c: data.Account.LeadWebhookUrl__c
    };

    // Remove empty fields
    Object.keys(contactInfo).forEach(
      (key) =>
        (contactInfo[key] == null || contactInfo[key] === "") &&
        delete contactInfo[key]
    );

    setForm({
        LeadWebhookUrl__c: contactInfo["LeadWebhookUrl__c"],
        accountId: data.AccountId
    });
    setIsLoading(false);
  };

  // As explained in the Login page.
  const onFormInputChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  // As explained in the Login page.
  const redirectNow = () => {
    const redirectTo = location.search.replace("?redirectTo=", "");
    navigate(redirectTo ? redirectTo : "/crm-integration");
  };

  // As explained in the Login page.
  const onSubmit = async () => {
    // Add your form submission logic here
    if (
      Boolean(
        formErrors.WebHookUrl__c ||
          false
      )
    ) {
      return;
    }

    try {
      await updateProfile();

      redirectNow();
    } catch (err) {
      console.error(err);
    }
  };

  const sendTestLead = async () => {
    setIsLoading(true);

    const access_token = await getValidAccessToken();

    const endpoint = `https://eu-central-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_REALM_APP_ID}/endpoint/salesforce/lead`;

    const body = {};
    body.FirstName = 'Max';
    body.LastName = 'Mustermann';
    body.Email = 'max.mustermann@gmail.com';
    body.Phone = '0173 6556 973';
    body.PostalCode = '30161';
    body.Salutation = 'Herr';
    body.Broker__c = contactData.contactId;

    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + access_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const data = await response;

    setIsLoading(false);
  }

  // Send request to update profile
  const updateProfile = async () => {
    setIsLoading(true);

    const access_token = await getValidAccessToken();

    const endpoint = `https://eu-central-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_REALM_APP_ID}/endpoint/salesforce/account`;

    const response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + access_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    });

    const data = await response;

    setIsLoading(false);
  };

  return (
    <div class="App">
        {/* If loading, display loading */}
      {isLoading || !profile ? (
        <Loading/>
      ) : (
    <div class="flex min-h-full flex-1 flex-col justify-center font-sans font-medium font-lg"
    >

      
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
            <h1
            style={{
            marginBottom: "1rem",
            }}
        >
            CRM Integration
        </h1>
        <p class="my-1.5">
            Hier kannst Du Deinen Webhook von bspw. Zapier hinterlegen und somit alle Leads direkt zu Deinem CRM synchronisieren. 
        </p>
        <p class="my-1.5">
            Wenn Du auf den Button "Test Lead senden" drückst, erstellt MaklerBridge einen Test Lead für Dich, um Deine Integration zu testen.
        </p>
            <form class="mt-10">
                <div>
                    <label htmlFor="LeadWebhookUrl__c" className="block text-sm font-medium leading-6 text-gray-900">
                    Webhook Url
                    </label>
                    <div className="mt-2">
                    <input
                        name="LeadWebhookUrl__c"
                        type="LeadWebhookUrl__c"
                        required
                        value={form.LeadWebhookUrl__c}
                        onChange={onFormInputChange}
                        className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    </div>
                </div>
                <div class="my-2.5">
                    <button
                        onClick={onSubmit} type="button"
                        className="flex w-full justify-center rounded-md bg-link-color px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cta-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        Speichern
                    </button>
                </div>
            </form>
            
            <div>
                <button
                    onClick={sendTestLead} type="button"
                    className="flex w-full justify-center rounded-md bg-link-color px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cta-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    Test Lead senden
                </button>
            </div>
        </div>
        </div>
      )}
    
    </div>
  );
}
