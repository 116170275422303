import { useContext, useState, useEffect } from "react";
import { UserContext } from "../contexts/user";
import { Link } from "react-router-dom";

export function NavigationBar() {
  const { getValidAccessToken, logOutUser } = useContext(UserContext);
  const [profile, setProfile] = useState({});

  useEffect(() => {
    getProfile();
  }, []);

  // Get Salesforce Access Token
  const getProfile = async () => {
    const access_token = await getValidAccessToken();

    const endpoint = `https://eu-central-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_REALM_APP_ID}/endpoint/salesforce/contact`;

    console.log("Endpoint: ", endpoint);

    const response = await fetch(endpoint, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + access_token,
      },
    });

    const data = await response.json();
    console.log("Data: ", data);

    const contactInfo = {
      Anrede: data.Salutation,
      Vorname: data.FirstName,
      Nachname: data.LastName,
      "E-Mail": data.Email,
      "Kalender Buchungs Link": data.CalendlyUrl__c,
      Profilbild: data.ProfileImageUrl__c,
    };

    // Remove empty fields
    Object.keys(contactInfo).forEach(
      (key) =>
        (contactInfo[key] == null || contactInfo[key] === "") &&
        delete contactInfo[key]
    );

    setProfile(contactInfo);
  };

  // This function is called when the user clicks the "Logout" button.
  const logOut = async () => {
    try {
      // Calling the logOutUser function from the user context.
      const loggedOut = await logOutUser();
      // Now we will refresh the page, and the user will be logged out and
      // redirected to the login page because of the <PrivateRoute /> component.
      if (loggedOut) {
        window.location.reload(true);
      }
    } catch (error) {
      alert(error);
    }
  };

  return (
  <>
    <nav class="flex items-center justify-between flex-wrap bg-link-color px-6 py-4">
  <div class="flex items-center flex-shrink-0 text-white mr-6">
    <img width="200px" src="/logo_white.png" alt="MaklerBridge Logo"/>
  </div>
  <div class="block lg:hidden">
    <button class="flex items-center px-3 py-2 border rounded text-white border-white">
      <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
    </button>
  </div>
  <div class="block lg:flex lg:items-center items-center">
    <div class="text-sm lg:flex-grow">
    <Link to="/leads" className="block lg:inline-block lg:mt-0 no-underline text-white hover:text-cta-color mr-4">Leads</Link>
    <Link to="/crm-integration" className="block lg:inline-block lg:mt-0 no-underline text-white hover:text-cta-color mr-4">CRM Integration</Link>
    <Link to={process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL} target="_blank" rel="noopener" className="block lg:inline-block lg:mt-0 no-underline text-white hover:text-cta-color mr-4">Abonnement</Link>
    <Link onClick={logOut} className="block lg:inline-block lg:mt-0 no-underline text-white hover:text-cta-color mr-4">Logout</Link>
    </div>
    <Link to="/profil" className="block lg:inline-block lg:mt-0"><img class="object-cover w-10 h-10 p-1 rounded-full ring-2 ring-gray-300 dark:ring-gray-500" src={profile.Profilbild} alt="Bordered avatar"></img></Link>
  </div>
</nav>
</>
    // <Navbar expand="lg" className="bg-body-tertiary">
    //   <Container fluid>
    //     <Link to="/">
    //       <Navbar.Brand>Maklerbridge</Navbar.Brand>
    //     </Link>
    //     <Navbar.Toggle aria-controls="navbarScroll" />
    //     <Navbar.Collapse id="navbarScroll">
    //       <Nav
    //         className="me-auto my-2 my-lg-0"
    //         style={{ maxHeight: "100px" }}
    //         navbarScroll
    //       >
    //         <Link to="/home" className="nav-link">
    //           Start
    //         </Link>
    //         <Link to="/leads" className="nav-link">
    //           Leads
    //         </Link>
    //         <Link to="/contact" className="nav-link">
    //           Contact
    //         </Link>
    //         <Link to="/account" className="nav-link">
    //           Account
    //         </Link>
    //       </Nav>
    //       <Form className="d-flex">
    //         <Button
    //           variant="outline-danger"
    //           style={{ marginRight: "1rem" }}
    //           onClick={logOut}
    //         >
    //           Abmelden
    //         </Button>

    //         <Link to="/profile">
    //           <Avatar alt="Profile Picture" src={profile.Profilbild} />
    //         </Link>
    //       </Form>
    //     </Navbar.Collapse>
    //   </Container>
    // </Navbar>
  );
}

export default NavigationBar;
