import { Button } from "@mui/material";
import { useContext } from "react";
import { UserContext } from "../contexts/user";
import { useState, useEffect } from "react";

export function ContactPage() {
  const { getValidAccessToken } = useContext(UserContext);

  const [contact, setContact] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const tableStyle = {
    borderCollapse: "collapse",
    border: "2px solid #000",
    width: "100%",
  };

  const cellStyle = {
    border: "1px solid #000",
    padding: "10px", // Add padding for better spacing
  };

  // Get Salesforce Access Token
  const getContact = async () => {
    setIsLoading(true);

    const access_token = await getValidAccessToken();

    const endpoint = `https://eu-central-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_REALM_APP_ID}/endpoint/salesforce/contact`;


    const response = await fetch(endpoint, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + access_token,
      },
    });

    const data = await response.json();

    const accountInfo = {
      Firma: data.Name,
      Straße: data.BillingStreet,
      PLZ: data.BillingPostalCode,
      Stadt: data.BillingCity,
      Telefon: data.Phone,
    };

    const contactInfo = {
      Anrede: data.Salutation,
      Vorname: data.FirstName,
      Nachname: data.LastName,
      "E-Mail": data.Email,
      "Kalender Buchungs Link": data.CalendlyUrl__c,
      Profilbild: data.ProfileImageUrl__c,
    };

    // Remove empty fields
    Object.keys(contactInfo).forEach(
      (key) =>
        (contactInfo[key] == null || contactInfo[key] === "") &&
        delete contactInfo[key]
    );

    setContact(contactInfo);
    setIsLoading(false);
  };

  useEffect(() => {
    getContact();
  }, []);

  return (
    <>
      <div class="App">
        <h1>Kontakt</h1>
        {isLoading && contact ? (
          <p>Wird geladen...</p>
        ) : (
          <div style={{ margin: "1rem" }}>
            <table style={tableStyle}>
              <thead>
                <tr>
                  {/* Map The Contact Keys into column names */}
                  {Object.keys(contact).map((key, index) => (
                    <th style={cellStyle}>{key}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {/* Map The Contact Values into row values */}
                  {Object.values(contact).map((key, index) => (
                    <th style={cellStyle}>{key}</th>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        )}
        <Button
          variant="contained"
          onClick={getContact}
          disabled={isLoading}
          style={{ marginBottom: "1rem", marginTop: "1rem" }}
        >
          {isLoading ? "Wird geladen..." : "Neu Laden"}
        </Button>
        {isLoading && contact ? <p>Wird geladen...</p> : null}
      </div>
    </>
  );
}
