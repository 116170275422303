import { useContext, useState } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { UserContext } from "../contexts/user";
// import { Form, Button, InputGroup, FormControl } from 'react-bootstrap';
import { getSelectedImage, uploadImage } from "../utils/image_upload";
import { Loading } from "../components/Loading";

const Signup = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const firstName = searchParams.get("firstName");
  const lastName = searchParams.get("lastName");
  const phone = searchParams.get("phone");
  const [isLoading, setIsLoading] = useState(false);

  // As explained in the Login page.
  const { emailPasswordSignup, getValidAccessToken } = useContext(UserContext);
  const [form, setForm] = useState({
    Email: email,
    Password: "",
    CalendlyUrl__c: "",
    Salutation: "Herr",
    Phone: phone
  });

  const [formErrors, setFormErrors] = useState({
    Email: "",
    Password: "",
    CalendlyUrl__c: "",
  });

  const [profileImage, setProfileImage] = useState(null);
  const [logoImage, setLogoImage] = useState(null);

  // As explained in the Login page.
  const onFormInputChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });

    // Use a switch statement for field-specific validation
    switch (name) {
      case "Email":
        if (!value.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
          setFormErrors({
            ...formErrors,
            Email: "Ungültige E-Mail-Adresse",
          });
        } else {
          setFormErrors({
            ...formErrors,
            Email: "",
          });
        }
        break;
      case "Password":
        if (value.length < 8) {
          setFormErrors({
            ...formErrors,
            Password: "Das Passwort muss mindestens 8 Zeichen lang sein",
          });
        } else {
          setFormErrors({
            ...formErrors,
            Password: "",
          });
        }
        break;

      case "CalendlyUrl__c":
        if (value.length === 0) {
          setFormErrors({
            ...formErrors,
            CalendlyUrl__c: "",
          });
        }
        // Match calendly.com links
        else if (!value.match(/^(https:\/\/calendly.com\/)([a-zA-Z0-9-]+)$/)) {
          setFormErrors({
            ...formErrors,
            CalendlyUrl__c: "Ungültiger Calendly Link",
          });
        } else {
          setFormErrors({
            ...formErrors,
            CalendlyUrl__c: "",
          });
        }
        break;

      default:
        if (value.length < 1) {
          setFormErrors({
            ...formErrors,
            [name]: "Dieses Feld darf nicht leer sein",
          });
        } else {
          setFormErrors({
            ...formErrors,
            [name]: "",
          });
        }
        break;
    }
  };

  // As explained in the Login page.
  const redirectNow = () => {
    let redirectTo = '/'
    if(location.search.indexOf("redirectTo") > -1) {
      redirectTo = location.search.replace("?redirectTo=", "");
    }
    navigate(redirectTo ? redirectTo : "/");
  };

  // As explained in the Login page.
  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const user = await emailPasswordSignup(form);
      // Add your form submission logic here
      // if (
      //   Boolean(
      //     formErrors.Email ||
      //       formErrors.Password ||
      //       formErrors.CalendlyUrl__c ||
      //       false
      //   )
      // ) {
      //   return;
      // }
      setForm({});
      const access_token = await getValidAccessToken();

      if (profileImage) {
        const imageUrl = await handleImageUpload(profileImage, 'profileImage');
        form.ProfileImageUrl__c = imageUrl;
      } else {
        delete form.ProfileImageUrl__c;
      }
      if (logoImage) {
        const imageUrl = await handleImageUpload(logoImage, 'logo');
        form.LogoImageUrl__c = imageUrl;
      } else {
        delete form.LogoImageUrl__c;
      }

    const endpoint = `https://eu-central-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_REALM_APP_ID}/endpoint/users`;

    await fetch(endpoint, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + access_token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    });

      if (user) {
        redirectNow();
      }
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  // Function to handle salutation selection
  const handleDropdownChange = (event) => {
    setForm({ ...form, Salutation: event.target.value });
  };

  async function handleProfileImageChange(e) {
    setProfileImage(await getSelectedImage(e));

    return profileImage;
  }

  async function handleLogoImageChange(e) {
    setLogoImage(await getSelectedImage(e));

    return logoImage;
  }

  async function handleImageUpload(image, fileName) {
    const imgUrl = await uploadImage(image, fileName);

    return imgUrl;
  }

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 font-sans font-medium font-lg">
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <img
        className="mx-auto h-14 w-auto"
        src="/logo_blue.png"
        alt="MaklerBridge Logo"
      />
    </div>
    {isLoading ? (
        <Loading/>
      ) : (
    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
      <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
        <form className="space-y-6">

        <span class="block text-sm font-medium leading-6 text-gray-900">Logo hochladen</span>
        <div style={{ margin: "1rem" }}>
          {logoImage && (
            <div className="text-center">
              <img
                alt="not found"
                width={"375px"}
                src={URL.createObjectURL(logoImage)}
                className="mr-4"
              />
              <div>
                <input
                  id="logoImage"
                  type="file"
                  name="logoImage"
                  onChange={handleLogoImageChange}
                  hidden
                />
                <br/>
                <div class="flex space-x-4 items-center">
                <div class="w-1/2 h-[36px]">
                <label for="logoImage">
                  <span className="flex cursor-pointer w-full justify-center rounded-md bg-link-color px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cta-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    Logo auswählen
                  </span>
                </label>
                </div>
                <div class="w-1/2">
                <button type="button" 
                        onClick={() => handleLogoImageChange(null)}
                        className="flex cursor-pointer w-full justify-center rounded-md bg-link-color px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cta-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  Logo entfernen
                </button>
                </div>
                </div>
              </div>
            </div>
          )}

          {!logoImage && (
            <div className="flex items-center text-center">
              <input
                id="logoImage"
                type="file"
                name="logoImage"
                onChange={handleLogoImageChange}
                hidden
              />
              <label for="logoImage" className="m-2">
                <span className="flex cursor-pointer w-full justify-center rounded-md bg-link-color px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cta-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  Logo auswählen
                </span>
              </label>
            </div>
          )}
        </div>

        <span class="block text-sm font-medium leading-6 text-gray-900">Profilbild hochladen</span>
        <div style={{ margin: "1rem" }}>
          {profileImage && (
            <div className="text-center">
              <img
                alt="not found"
                width={"375px"}
                src={URL.createObjectURL(profileImage)}
                className="mr-4"
              />
              <div>
                <input
                  id="profileImage"
                  type="file"
                  name="profileImage"
                  onChange={handleProfileImageChange}
                  hidden
                />
                <br/>
                <div class="flex space-x-4 items-center">
                <div class="w-1/2 h-[36px]">
                <label for="profileImage">
                  <span className="flex cursor-pointer w-full justify-center rounded-md bg-link-color px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cta-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    Profilbild auswählen
                  </span>
                </label>
                </div>
                <div class="w-1/2">
                <button type="button" 
                        onClick={() => handleProfileImageChange(null)}
                        className="flex cursor-pointer w-full justify-center rounded-md bg-link-color px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cta-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  Profilbild entfernen
                </button>
                </div>
                </div>
              </div>
            </div>
          )}

          {!profileImage && (
            <div className="flex items-center text-center">
              <input
                id="profileImage"
                type="file"
                name="profileImage"
                onChange={handleProfileImageChange}
                hidden
              />
              <label for="profileImage" className="m-2">
                <span className="flex cursor-pointer w-full justify-center rounded-md bg-link-color px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cta-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  Profilbild auswählen
                </span>
              </label>
            </div>
          )}
        </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
              E-Mail
            </label>
            <div className="mt-2">
              <input
                name="email"
                type="email"
                disabled
                value={form.Email}
                onChange={onFormInputChange}
                className="disabled:bg-gray-100 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
              Passwort
              <div class="leading-[14px]">
                <small>Mindestens 8 Zeichen</small>
              </div>
            </label>
            <div className="mt-2">
              <input
                name="Password"
                type="password"
                autoComplete="current-password"
                required
                onChange={onFormInputChange}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <label for="salutation" class="block text-sm font-medium leading-6 text-gray-900">Anrede</label>
            <select id="salutation" onChange={handleDropdownChange} name="salutation" class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <option selected>Herr</option>
              <option>Frau</option>
            </select>
          </div>
          <div>
            <label htmlFor="FirstName" className="block text-sm font-medium leading-6 text-gray-900">
              Vorname
            </label>
            <div className="mt-2">
              <input
                name="FirstName"
                type="text"
                value={firstName}
                disabled
                className="disabled:bg-gray-100 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <label htmlFor="LastName" className="block text-sm font-medium leading-6 text-gray-900">
              Nachname
            </label>
            <div className="mt-2">
              <input
                name="LastName"
                type="text"
                value={lastName}
                disabled
                className="disabled:bg-gray-100 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label htmlFor="CalendlyUrl__c" className="block text-sm font-medium leading-6 text-gray-900">
              Calendly Link
            </label>
            <div className="mt-2">
              <input
                name="CalendlyUrl__c"
                type="text"
                placeholder="https://calendly.com/dein-link"
                onChange={onFormInputChange}
                className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label htmlFor="Phone" className="block text-sm font-medium leading-6 text-gray-900">
              Telefon<br/>
              <div class="leading-[14px]">
                <small>Die Telefonnummer unter der Dich Deine Leads erreichen können. Diese zeigen wir beispielsweise in der personalisierten E-Mail an die Leads</small>
              </div>
            </label>
            <div className="mt-2">
              <input
                name="Phone"
                type="text"
                value={form.Phone}
                onChange={onFormInputChange}
                className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <button
              onClick={onSubmit} type="button"
              className="flex w-full justify-center rounded-md bg-link-color px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cta-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Registrieren
            </button>
          </div>
        </form>
        <small>Hast Du bereits ein Konto? <Link to="/login">Einloggen</Link></small>
      </div>
    </div>
  )}
  </div>
    
  );
};

export default Signup;
