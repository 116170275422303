import { useContext, useState } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { UserContext } from "../contexts/user";
import { Credentials } from "realm-web";
import { Loading } from "../components/Loading";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [requestSent, setRequestSent] = useState(false);

  // As explained in the Login page.
  const { resetUserPassword } = useContext(UserContext);
  const [form, setForm] = useState({
    Email: "",
    Password: ""
  });

  const [formErrors, setFormErrors] = useState({
    Email: "",
    Password: ""
  });

  // As explained in the Login page.
  const onFormInputChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });

    // Use a switch statement for field-specific validation
    switch (name) {
      case "Email":
        if (!value.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
          setFormErrors({
            ...formErrors,
            Email: "Ungültige E-Mail-Adresse",
          });
        } else {
          setFormErrors({
            ...formErrors,
            Email: "",
          });
        }
        break;
      case "Password":
        if (value.length < 8) {
          setFormErrors({
            ...formErrors,
            Password: "Das Passwort muss mindestens 8 Zeichen lang sein",
          });
        } else {
          setFormErrors({
            ...formErrors,
            Password: "",
          });
        }
        break;

      default:
        if (value.length < 1) {
          setFormErrors({
            ...formErrors,
            [name]: "Dieses Feld darf nicht leer sein",
          });
        } else {
          setFormErrors({
            ...formErrors,
            [name]: "",
          });
        }
        break;
    }
  };

  // As explained in the Login page.
  const redirectNow = () => {
    let redirectTo = '/'
    if(location.search.indexOf("redirectTo") > -1) {
      redirectTo = location.search.replace("?redirectTo=", "");
    }
    navigate(redirectTo ? redirectTo : "/");
  };

  // As explained in the Login page.
  const onSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
        await resetUserPassword(form);
        setRequestSent(true);
    } catch (error) {
      alert(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 font-sans font-medium font-lg">
      {isLoading ? (
      <Loading/>
    ) : (
      <div>
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-14 w-auto"
            src="/logo_blue.png"
            alt="MaklerBridge Logo"
          />
        </div>
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          {requestSent && (
            <div class="mb-6 font-medium">Dir wurde eine E-Mail gesendet, um das Passwort zurückzusetzen. Bitte folge den Anweisungen in Deiner E-Mail. Sollte innerhalb von einer Minute keine E-Mail ankommen, siehe bitte in dem Spam Ordner nach.</div>
          )}
            <form className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  E-Mail
                </label>
                <div className="mt-2">
                  <input
                    name="Email"
                    type="email"
                    autoComplete="email"
                    required
                    placeholder="maxmustermann@gmail.com"
                    value={form.Email}
                    onChange={onFormInputChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <button
                  onClick={onSubmit} type="submit"
                  className="flex w-full justify-center rounded-md bg-link-color px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cta-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Passwort zurücksetzen
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )}
  </div>
    
  );
};

export default ResetPassword;
