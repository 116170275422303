import { Button } from "@mui/material";
import { useContext } from "react";
import { UserContext } from "../contexts/user";
import { useState, useEffect } from "react";

export function AccountPage() {
  const { getValidAccessToken } = useContext(UserContext);

  const [account, setAccount] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const tableStyle = {
    borderCollapse: "collapse",
    border: "2px solid #000",
    width: "100%",
  };

  const cellStyle = {
    border: "1px solid #000",
    padding: "10px", // Add padding for better spacing
  };

  // Get Salesforce Access Token
  const getAccount = async () => {
    setIsLoading(true);

    const access_token = await getValidAccessToken();

    const endpoint = `https://eu-central-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_REALM_APP_ID}/endpoint/salesforce/contact`;

    console.log("Endpoint: ", endpoint);

    const response = await fetch(endpoint, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + access_token,
      },
    });

    const data = await response.json();
    console.log("Data: ", data);

    const accountInfo = {
      Firma: data.Name,
      Straße: data.BillingStreet,
      PLZ: data.BillingPostalCode,
      Stadt: data.BillingCity,
      Telefon: data.Phone,
    };

    // Remove empty fields
    Object.keys(accountInfo).forEach(
      (key) =>
        (accountInfo[key] == null || accountInfo[key] === "") &&
        delete accountInfo[key]
    );

    setAccount(accountInfo);
    setIsLoading(false);
  };

  useEffect(() => {
    getAccount();
  }, []);

  return (
    <>
      <div class="App">
        <h1>Konto</h1>
        {isLoading && account ? (
          <p>Wird geladen...</p>
        ) : (
          <div style={{ margin: "1rem" }}>
            <table style={tableStyle}>
              <thead>
                <tr>
                  {/* Map The Account Keys into column names */}
                  {Object.keys(account).map((key, index) => (
                    <th style={cellStyle}>{key}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {/* Map The Account Values into row values */}
                  {Object.values(account).map((key, index) => (
                    <th style={cellStyle}>{key}</th>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        )}
        <Button
          variant="contained"
          onClick={getAccount}
          disabled={isLoading}
          style={{ marginBottom: "1rem", marginTop: "1rem" }}
        >
          {isLoading ? "Wird geladen..." : "Neu Laden"}
        </Button>
        {isLoading && account ? <p>Wird geladen...</p> : null}
      </div>
    </>
  );
}
