import React from "react";
import { createContext, useState } from "react";
import { Credentials } from "realm-web";
import * as Realm from "realm-web";

// Set REALM_APP_ID
const REALM_APP_ID: string = process.env.REACT_APP_REALM_APP_ID as string;

// Add your App ID
const app: Realm.App = new Realm.App({ id: REALM_APP_ID });

// Creating a user context to manage and access all the user related functions
// across different components and pages.
export const UserContext = createContext<Realm.User | null>(null);

export const UserProvider = ({ children }: { children: any }) => {
  const [user, setUser] = useState<Realm.User | null>(null);
  const [profile, setProfile] = useState<any>(null);

  // Function to login user into our Realm using their email & password
  const emailPasswordLogin = async (form: any) => {
    const email: string = form.email ?? form.Email;
    const password: string = form.password ?? form.Password;

    const credentials = Credentials.emailPassword(email, password);
    const authedUser = await app.logIn(credentials);
    setUser(authedUser);

    const user = await getUserCustomData();

    if (user) {
    } else {
      await createUserRecord(form);

      const user = await getUserCustomData();
    }

    return authedUser;
  };

  // Function to signup user into our Realm using their email & password
  const emailPasswordSignup = async (form: any) => {
    try {
      const email: string = form.Email;
      const password: string = form.Password;

      await app.emailPasswordAuth.registerUser({ email, password });

      // Since we are automatically confirming our users we are going to login
      // the user using the same credentials once the signup is complete.
      return await emailPasswordLogin(form);
    } catch (error) {
      throw error;
    }
  };

  // Function to login user into our Realm using their email & password
  const resetUserPassword = async (form: any) => {
    const email: string = form.Email;
    const response = await app.emailPasswordAuth.callResetPasswordFunction({email, password: 'ABCDEFGH123$'}, []);
    return response;
  };

  const confirmResetUserPassword = async (form:any, token:any, tokenId:any) => {
    const password: string = form.Password;
    const response = await app.emailPasswordAuth.resetPassword({token, tokenId, password});
    return response;
  }

  // Using this function, you don’t need to login the user again and again. You can use this function to check if the user is already logged in.
  const fetchUser = async () => {
    if (!app.currentUser) return false;
    try {
      await app.currentUser.refreshCustomData();
      // Now if we have a user we are setting it to our user context
      // so that we can use it in our app across different components.
      setUser(app.currentUser);
      return app.currentUser;
    } catch (error) {
      throw error;
    }
  };

  // Function to logout user from our Realm
  const logOutUser = async () => {
    if (!app.currentUser) return false;
    try {
      await app.currentUser.logOut();
      // Setting the user to null once loggedOut.
      setUser(null);
      return true;
    } catch (error) {
      throw error;
    }
  };

  // Gets a valid Realm user access token to authenticate requests
  const getValidAccessToken = async () => {
    // Guarantee that there's a logged in user with a valid access token to use
    if (!app.currentUser) {
      return;
    }

    await app.currentUser.refreshAccessToken();

    return app.currentUser.accessToken;
  };

  // Create User Record in DB
  const createUserRecord = async (form: any) => {
    // Guarantee that there's a logged in user with a valid access token to use
    if (!app.currentUser) {
      return;
    }

    const access_token = await getValidAccessToken();

    const endpoint = `https://eu-central-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_REALM_APP_ID}/endpoint/sign_up`;

    const response = await fetch(
      endpoint,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(form),
      }
    );

    const data = await response.json();

    return data;
  };

  // Get User Custom Data from DB
  const getUserCustomData = async () => {
    // Guarantee that there's a logged in user with a valid access token to use
    if (!app.currentUser) {
      return;
    }

    const access_token = await getValidAccessToken();

    const endpoint = `https://eu-central-1.aws.data.mongodb-api.com/app/${process.env.REACT_APP_REALM_APP_ID}/endpoint/users`;

    const response = await fetch(
      endpoint,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + access_token,
        },
      }
    );

    const data = await response.json();

    if (data && data.error) {
      return null;
    }

    setProfile(data);

    return data;
  };

  return (
    <UserContext.Provider
      value={
        {
          user,
          setUser,
          fetchUser,
          emailPasswordLogin,
          emailPasswordSignup,
          logOutUser,
          getValidAccessToken,
          resetUserPassword,
          confirmResetUserPassword
        } as any
      }
    >
      {children}
    </UserContext.Provider>
  );
};
