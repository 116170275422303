export function Footer() {

  return (
  <>
    <footer
  class="flex flex-col sticky top-[100vh] items-center bg-link-color text-center">
  <div
    class="w-full p-6 text-center">
    <a class="no-underline text-white hover:text-cta-color mr-4"
      href="https://maklerbridge.de/impressum"
      target="_blank"
      rel="noreferrer"
      >Impressum</a>
      <a
      class="no-underline text-white hover:text-cta-color"
      href="https://maklerbridge.de/datenschutz"
      target="_blank"
      rel="noreferrer"
      >Datenschutz</a>
  </div>
</footer>
</>
    // <Navbar expand="lg" className="bg-body-tertiary">
    //   <Container fluid>
    //     <Link to="/">
    //       <Navbar.Brand>Maklerbridge</Navbar.Brand>
    //     </Link>
    //     <Navbar.Toggle aria-controls="navbarScroll" />
    //     <Navbar.Collapse id="navbarScroll">
    //       <Nav
    //         className="me-auto my-2 my-lg-0"
    //         style={{ maxHeight: "100px" }}
    //         navbarScroll
    //       >
    //         <Link to="/home" className="nav-link">
    //           Start
    //         </Link>
    //         <Link to="/leads" className="nav-link">
    //           Leads
    //         </Link>
    //         <Link to="/contact" className="nav-link">
    //           Contact
    //         </Link>
    //         <Link to="/account" className="nav-link">
    //           Account
    //         </Link>
    //       </Nav>
    //       <Form className="d-flex">
    //         <Button
    //           variant="outline-danger"
    //           style={{ marginRight: "1rem" }}
    //           onClick={logOut}
    //         >
    //           Abmelden
    //         </Button>

    //         <Link to="/profile">
    //           <Avatar alt="Profile Picture" src={profile.Profilbild} />
    //         </Link>
    //       </Form>
    //     </Navbar.Collapse>
    //   </Container>
    // </Navbar>
  );
}

export default Footer;
