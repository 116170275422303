import { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { UserContext } from "../contexts/user";
import { NavigationBar } from "../components/Navigation_Bar";
import { Footer } from "../components/Footer";

const PrivateRoute = (props) => {
  // Fetching the user from the user context.
  const { user } = useContext(UserContext);
  const location = useLocation();

  // If the user is not logged in we are redirecting them
  // to the login page. Otherwise we are letting them to
  // continue to the page as per the URL using <Outlet />.
  return user ? (
    <div class="min-h-screen font-sans  font-normal font-base"> 
      <NavigationBar />
      <Outlet />
      <Footer />
    </div>
  ) : (
    <Navigate to={`/login?redirectTo=${encodeURI(location.pathname)}`} />
  );
};

export default PrivateRoute;
